<!-- =========================================================================================
    File Name: TableExpandableData.vue
    Description: Create expandable rows which have custom data
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vx-card title="แก้ไขลิงค์ทางเข้าเล่น">
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <vs-input
          class="w-full mt-4"
          v-validate="'required'"
          label="ชื่อปุ่ม"
          placeholder="กรุณาใส่ชื่อปุ่ม"
          v-model="new_data.text"
          name="text"
        />
        <span class="text-danger text-sm" v-show="errors.has('text')">{{
          errors.first("text")
        }}</span>
        <vs-input
          class="w-full mt-4"
          v-validate="'required'"
          label="url"
          placeholder="กรุณาใส่ url"
          v-model="new_data.url"
          name="url"
        />
        <span class="text-danger text-sm">ต้องมี / ตามหลังเสมอ</span>
        <span class="text-danger text-sm" v-show="errors.has('url')">{{
          errors.first("url")
        }}</span>
        <br />
        <vs-input
          class="w-full mt-4"
          v-validate="'required'"
          label="urlMobile"
          placeholder="กรุณาใส่ urlMobile"
          v-model="new_data.urlMobile"
          name="urlMobile"
        />
        <span class="text-danger text-sm">ต้องมี / ตามหลังเสมอ</span>
        <span class="text-danger text-sm" v-show="errors.has('urlMobile')">{{
          errors.first("urlMobile")
        }}</span>
        <br />
      </div>
    </div>
    <div class="mt-8 flex flex-wrap items-center justify-end">
      <vs-button color="success" class="ml-4 mt-2" @click="Save"
        >ยืนยัน</vs-button
      >
    </div>
  </vx-card>
</template>

<script>
import { Validator } from 'vee-validate'
import vSelect from 'vue-select'
import axios from '../../axios'

const dict = {
  custom: {
    username: {
      required: 'กรุณากรอกข้อมูล',
      alpha_num: 'ตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น',
      max: 'กรุณากรอกตัวอักษรไม่เกิน20ตัว'
    },
    password: {
      required: 'กรุณากรอกข้อมูล',
      alpha_num: 'ตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น'
    },
    fullname: {
      required: 'กรุณากรอกข้อมูล'
    },
    bankno: {
      required: 'กรุณากรอกข้อมูล',
      numeric: 'กรุณากรอกแต่ตัวเลขเท่านั้น',
      max: 'กรุณากรอกตัวอักษรไม่เกิน10ตัว'
    },
    phone: {
      required: 'กรุณากรอกข้อมูล',
      numeric: 'กรุณากรอกแต่ตัวเลขเท่านั้น'
    }
  }
}
Validator.localize('en', dict)
export default {
  components: {
    vSelect
  },
  data () {
    return {
      status: false,
      bankfrom: this.$route.params.bank,
      bankid: this.$route.params.id,
      dataUrl: [],
      datagroups: [
        {
          text: 'บัญชีหลัก',
          value: 0
        },
        {
          text: 'บัญชีสำรอง',
          value: 1
        }
      ],
      new_data: {
        id: '',
        text: '',
        url: '',
        urlMobile: '',
        icon: '',
        order_url: ''
      },
      info_log: ''
    }
  },
  async mounted () {
    await axios
      .get(
        `settingsystem/geturl/${this.$route.params.id}`
      )
      .then((response) => (this.dataUrl = response.data))
    this.new_data.text = this.dataUrl[0].text
    this.new_data.url = this.dataUrl[0].url
    this.new_data.urlMobile = this.dataUrl[0].urlMobile
  },
  methods: {
    async Save () {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          await axios
            .post('settingsystem/updateurl', {
              id: this.dataUrl[0].id,
              text: this.new_data.text,
              url: this.new_data.url,
              urlMobile: this.new_data.urlMobile
            })
            .then((response) => (this.status = response.data))
          if (this.status.status === false) {
            this.$vs.notify({
              time: 8000,
              color: 'danger',
              position: 'top-right',
              icon: 'error',
              title: 'บันทึกไม่สำเร็จ',
              text: this.status.info
            })
          } else {
            this.$vs.notify({
              time: 3000,
              color: 'success',
              position: 'top-right',
              icon: 'check_box',
              title: this.status.info
            })


            setTimeout(() => {
              this.$router.push('/SettingSystem')
            }, 1500)
          }
        } else {
          this.$vs.notify({
            time: 8000,
            color: 'danger',
            position: 'top-right',
            icon: 'error',
            title: 'ไม่ได้กรอกข้อมูล',
            text: 'กรุณากรอกข้อมูลให้ครบถ้วน'
          })
        }
      })
    }
  }
}
</script>
